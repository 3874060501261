
import { defineComponent, toRefs} from 'vue'

export default defineComponent({
	props: {
		callback: {
			type: Function,
			required: true,
		},
		callbackParams: {
			type: Object,
			default () {
				return {};
			}
		},
		callbackString: {
			type: String,
			default: '',
		},
		modifier: {
			type: String,
			default: 'primary',
		},
		size: {
			type: String,
			default: 'default',
		},
		shape: {
			type: String,
			default: ''
		}
	},
	setup (props) {
		const { callback, callbackParams, callbackString } = toRefs<any>(props);

		/**
		 * Button callback function
		 */
		function callbackFn () {
			if (Object.keys(callbackParams.value).length !== 0) {
				callback.value(callbackParams.value);
			} else {
				callback.value(callbackString.value);
			}
		}

		return { callbackFn }
	},
})
