/**
 * Bootstrap registry module
 * 
 * Author: Sahil David
 */

import router from './services/router';
import store from './services/store';

import { IModule, IModuleName, IModuleObject, Dictionary } from '@/@types';

/**
 * Wrapper to register store and router in main instances
 * @param name - as IModuleName to register module type as store | router
 * @param module - module to register
 */
const registerModule = (name: IModuleName, module: IModule) => {
	if (module.store && Array.isArray(module.store)) {
		module.store.forEach((storeModule: IModuleObject) => {
			store.registerModule(storeModule.name, storeModule.store);
		});
	} else {
		store.registerModule(name, module.store);
	}

	if (module.router) {
		module.router(router);
	}
};

/**
 * Function to register module to main Vue instance
 * @param modules - modules as IModule to register modules exported
 */
export const registerModules = (modules: Dictionary<IModule>) => {
	Object.keys(modules).forEach((moduleKey: string) => {
		const module = modules[moduleKey];
		registerModule(moduleKey as IModuleName, module);
	});
};