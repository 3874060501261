
import { defineComponent } from 'vue';
import { overlayComposition } from '../services';

export default defineComponent({
	props: {
		modifier: {
			type: String,
			default: 'default',
		}
	},
	setup () {
		return { ...overlayComposition() }
	}
})
