/**
 * Save tokens in local storage
 */
export function saveTokens (accessToken: string, refreshToken: string): void {
	localStorage.setItem('datota_access_token', accessToken);
	localStorage.setItem('datota_refresh_token', refreshToken);
}

/**
 * Get tokens from local storage
 */
export function getTokens (): any {
	const accessToken = localStorage.getItem('datota_access_token');
	const refreshToken = localStorage.getItem('datota_refresh_token');

	return { accessToken, refreshToken };
}

/**
 * Remove saved tokens from local storage
 */
export function removeTokens (): void {
	localStorage.removeItem('datota_access_token');
	localStorage.removeItem('datota_refresh_token');
}
