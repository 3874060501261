/**
 * Module router file
 */
import { RouteRecordRaw } from 'vue-router';

const Module = () => import('./Module.vue');
const Login = () => import('./views/Login.vue');
const Logout = () => import('./views/Logout.vue');

/**
 * Route main object
 */
const moduleRoute: RouteRecordRaw = {
	path: '',
	component: Module,
	children: [
		{
			path: 'login',
			component: Login,
			name: 'login'
		},
		{
			path: 'logout',
			component: Logout,
			name: 'logout',
			meta: {
				disableAuthenticationValidation: true,
			}
		}
	]
}

export default moduleRoute;
