/**
 * Register all plugins/libraries to the Vue instance
 * 
 * Author: Sahil David <sahil.david@huumun.com>
 */
import { App } from 'vue';

/**
 * Register base components
 * @param app - app to register components to
 */
function plugins (app: App<Element>) {
	// Require components in base context
	const requireComponent = require.context('.', true, /index.ts$/);

	requireComponent.keys().forEach((filename: string) => {
		if (filename === './index.ts') { return; }

		// Get plugin method
		const pluginMethod = requireComponent(filename);
		const main = pluginMethod.default || pluginMethod;

		if (typeof main !== 'function') { return; }

		// Call plugin main method
		main(app);
	});
}

/**
 * Components registration
 */
export default (app: App<Element>): void => {
	plugins(app);
}