<!--
 - layout-app.vue
 - Core component of application main layout
 -
 - Author: Sahil David <sahil.david@huumun.com>
-->

<template>
	<div class="hd-app-layout">
		<!-- Header -->
		<div class="hd-app-layout__header">
			<!-- App Bar -->
			<app-bar-module />
		</div>
		<div class="hd-app-layout__content">
			<!-- Main Content Slot -->
			<slot />
		</div>

		<!-- Create Module -->
		<action-create-module />
	</div>
</template>

<style lang="scss" scoped>
	.hd-app-layout {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		background: $bg-app;

		&__content {
			flex: 1;
			padding: $layout-padding-mobile;
		}
	}
</style>