
import { defineComponent } from 'vue';

// Compositions
import { menuComposition } from '../services';

export default defineComponent({	
	setup () {
		return { ...menuComposition() }
	}
})
