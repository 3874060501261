/**
 * Overlay - vuex module
 */
import { MutationTree, ActionTree, GetterTree } from 'vuex';

import { IOverlayState } from '../@types';
 
/**
  * State
  */
const state: IOverlayState = {
	overlay: ''
};
 
/**
  * Mutations
  */
const mutations: MutationTree<IOverlayState> = {
	updateOverlay (stateObj: IOverlayState, overlay: string) {
		stateObj.overlay = overlay;
	},
};
 
/**
 * Getters
 */
const getters: GetterTree<IOverlayState, any> = {
	get_overlay (stateObj: IOverlayState): string {
		return stateObj.overlay;
	},
};
 
/**
 * Actions
 */
const actions: ActionTree<IOverlayState, any> = {
	axn_updateOverlay (context: any, overlay: string) {
		context.commit('updateOverlay', overlay);
	},
};
 
/**
 * Export
 */
export default {
	namespace: true,
	state,
	mutations,
	getters,
	actions,
};
 