/**
 * menu - vuex module
 */
import { MutationTree, ActionTree, GetterTree } from 'vuex';

import { IAppBarState } from '../@types';
 
/**
  * State
  */
const state: IAppBarState = {
	menu: ''
};
 
/**
  * Mutations
  */
const mutations: MutationTree<IAppBarState> = {
	updateMenu (stateObj: IAppBarState, menu: string) {
		stateObj.menu = menu;
	},
};
 
/**
 * Getters
 */
const getters: GetterTree<IAppBarState, any> = {
	get_menu (stateObj: IAppBarState): string {
		return stateObj.menu;
	},
};
 
/**
 * Actions
 */
const actions: ActionTree<IAppBarState, any> = {
	axn_updateMenu (context: any, menu: string) {
		context.commit('updateMenu', menu);
	},
};
 
/**
 * Export
 */
export default {
	namespace: true,
	state,
	mutations,
	getters,
	actions,
};
 