import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f74d11a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
			'hd-base-image-svg',
			`hd-base-image-svg__${_ctx.modifier}`,
			`hd-base-image-svg__colour-${_ctx.colour}`
		])
  }, [
    (_ctx.source && !_ctx.isImage)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          innerHTML: _ctx.source
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.source && _ctx.isImage)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _ctx.source
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ], 2))
}