/**
 * Register modules from core in global framework
 * 
 * Author: Sahil David
 */

import { Dictionary, IModule } from "@/@types";

/**
 * Global object to register module as router and store
 */
const modules: IModule = {
	store: []
};

/**
 * Export store modules
 */
try {
	const requireModule = require.context('./modules', true, /store$/);
	requireModule.keys().forEach((filename: string) => {
		const module = requireModule(filename);
		const moduleObj = module.default || module;

		const fragments = filename.split('/');
		const [, moduleName] = fragments;

		modules.store.push(
			{ 
				name: moduleName,
				store: moduleObj 
			}
		);
	});
} catch (err) {
	console.warn(err);
}

/**
 * Export wrapper for all services
 */
export const coreModules: Dictionary<IModule> = {
	core: modules,
}