
import { defineComponent, onMounted, ref } from 'vue';

import { displayRestrictionsConfig } from '../config/display-restrictions.config';

export default defineComponent({
    components: {
    },
    setup() {
        // Data.
        const { width, height } = displayRestrictionsConfig;

        // Reactive props.
        const restricted = ref(false);

        // Main restrict check.
        function evaluateRestrictions () {
            const lowerWidth = window.innerWidth < width;
            const lowerHeight = window.innerHeight < height;

            restricted.value = lowerWidth || lowerHeight;
        }

        // Lifecycle.
        onMounted(() => {
            evaluateRestrictions();
            window.addEventListener('resize', evaluateRestrictions);
        });

        // Template scope.
        return { restricted };
    }
})
