/**
 * Composition method for menu functions
 * 
 * Author: Sahil David
 */
import { computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export function menuComposition () {
	const store = useStore();
	const route = useRoute();

	// Computed store value
	const menu = computed((): string => store.getters.get_menu);

	watch(() => route.path, processMenu);
	onMounted(processMenu);

	// Close overlay method
	function processMenu (key = ''): void {
		const keyToUpdate = key && menu.value === key ? '' : key;
		store.dispatch('axn_updateMenu', keyToUpdate);
	}

	return { menu, processMenu }
}