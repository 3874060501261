/**
 * Export application module
 */
import router from './router';
import store from './store';

export default {
	router,
	store: store()
};
 