import paths from '@/config/routing.paths';

/**
 * Redirect URL, after login
 */
export function redirectUrl () {
	const { dashboard } = paths;
	let nextPage = dashboard;

	/**
	 * Redirect to the session page if any
	 */
	const storedUrl = sessionStorage.getItem('redirectURL');
	if (storedUrl) { nextPage = storedUrl; }

	return nextPage;
}

/**
 * Redirect to Dashboard after login
 * @param {String} name 
 * @param {Function} next 
 */
export async function redirectToApp (name: string, next: any) {
	const { loginName } = paths;
	if (name === loginName) { next({ path: redirectUrl() }); }
	else { 
		next();
	}
}

/**
 * Redirect to Login Page
 * @param {String} name 
 * @param {Function} next 
 */
export function redirectToLogin (name: string, next: any) {
	const { loginName, root } = paths;
	if (name !== loginName) { next({ path: root }); }
	else { next(); }
}
