/**
 * Module export file
 * 
 * Author: Sahil David <sahil.david@huumun.com>
 */

import auth from './authState';

export default {
	auth
}
  