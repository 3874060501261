/**
 * Main app configuration object.
 *
 * Author: Margarida López <margarida.lopez@huumun.com>
 */

// App configuration object.
const config = () => ({
    core: {
        modules: {
            displayRestrictions: {
                width: 1260,
                height: 720,
            },
        },
    },
});

// Export config
export const appConfig = config();

// Export interface (optional properties)
type AppConfigRequired = ReturnType<typeof config>;
export type AppConfig = Partial<AppConfigRequired>;
