/**
 * Composition method for form-field module.
 *
 * Author: Margarida López <margarida.lopez@huumun.com>
 */
import { ref, toRefs, watchEffect } from 'vue';

export function formFieldComposition (props) {
	const { callback } = toRefs(props);

	// Data
	const value = ref<string>('');

	watchEffect(() => {
		callback.value(value.value);
	})

	return { value };
}
