/**
 * Application config file
 */

import { IAppConfig } from '@/@types/IApplication';

const appConfig: IAppConfig = {
	name: 'datota'
};

export default appConfig;