/**
 * Application index router file
 * 
 * Author: Sahil David <sahil.david@huumun.com>
 */
import { Router, RouteRecordRaw } from 'vue-router';
import appConfig from './config';

/**
 * Views to import
 */
const Index = () => import('./Index.vue');

/**
 * Main route object
 */
const route: RouteRecordRaw = {
	path: `/${appConfig.name}`,
	name: `${appConfig.name}-index`,
	component: Index,
	redirect: `/${appConfig.name}/login`,
	children: [
		/**
		 * Application sub routes to be added here
		 */
	]
}

/**
 * Export modules
 */
try {
	const requireModule = require.context('./modules', true, /index$/);
	requireModule.keys().forEach((filename: string) => {
		const module = requireModule(filename);
		const moduleObj = module.default || module;

		if (!moduleObj.router) { return; }
		route.children?.push(moduleObj.router);
	});
} catch (err) {
	console.warn(err);
}

/**
 * Exporting wrapper function to be added to main router object
 */
export default (router: Router): void => {
	router.addRoute(route);
}
