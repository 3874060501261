import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([
			'hd-base-button',
			`hd-base-button__${_ctx.modifier}`,
			`hd-base-button__size-${_ctx.size}`,
			`hd-base-button__shape-${_ctx.shape}`,
		]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.callbackFn && _ctx.callbackFn(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}