
import { defineComponent } from 'vue';

// Lyra Function
export default defineComponent({
	setup () {
		return {
			/**
			 * TODO: App mounted functions
			 */
		}
	},
})
