/**
 * Main store entry point
 * 
 * Author: Sahil David <sahil.david@huumun.com>
 */

import { createStore } from 'vuex';

export default createStore({
	state: {},
	mutations: {},
	actions: {},
	modules: {}
});