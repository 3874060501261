<!--
 - loader-default.vue
 - Core component to load default loader
 -
 - Author: Sahil David <sahil.david@huumun.com>
-->
<template>
	<div class="hd-loader">
		<div class="hd-loader__element">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.hd-loader {
		@include flex-center();
		@include box(100%);

		background: $loader-bg;
		border-radius: $border-radius;

		&__element {
			position: relative;
			@include box(50px);

			& > div {
				position: absolute;
				top: 25px;
				@include box(10px);
				border-radius: 50%;
				background: $loader-element;
				animation-timing-function: cubic-bezier(0, 1, 1, 0);

				&:nth-child(1) {
					left: 8px;
					animation: hd-loader-1 0.6s infinite;
				}
				&:nth-child(2) {
					left: 8px;
					animation: hd-loader-2 0.6s infinite;
				}
				&:nth-child(3) {
					left: 32px;
					animation: hd-loader-2 0.6s infinite;
				}
				&:nth-child(4) {
					left: 56px;
					animation: hd-loader-3 0.6s infinite;
				}
			}
		}
	}

	// Animations keyframes
	@keyframes hd-loader-1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}
	@keyframes hd-loader-3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
	@keyframes hd-loader-2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(15px, 0);
		}
	}
</style>