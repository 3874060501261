import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-605163b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hd-action-create" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hd_icon = _resolveComponent("hd-icon")!
  const _component_menu_module = _resolveComponent("menu-module")!
  const _component_app_bar_menu = _resolveComponent("app-bar-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "hd-action-create__wrapper",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.processMenu('create')))
    }, [
      _createVNode(_component_hd_icon, {
        class: _normalizeClass([
					'hd-action-create__icon',
					{ 'is-active': _ctx.menu === 'create' }
				]),
        icon: "plus"
      }, null, 8, ["class"])
    ]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.menu === 'create')
          ? (_openBlock(), _createBlock(_component_app_bar_menu, {
              key: 0,
              modifier: "bottom-right"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_menu_module, { menu: "create" })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}