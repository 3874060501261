import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08dd2c3d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hd-form-field-module" }
const _hoisted_2 = { class: "hd-form-field-module__content" }
const _hoisted_3 = ["type", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_image_svg = _resolveComponent("base-image-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_base_image_svg, {
          key: 0,
          class: "hd-form-field-module__icon",
          source: require(`!html-loader!../assets/icons/${_ctx.icon}.svg`)
        }, null, 8, ["source"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([
					'hd-form-field-module__label',
					{ 'is-active': (_ctx.value.length > 0 || true) }
				])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2),
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass([
					'hd-form-field-module__input',
					{ 'is-active': (_ctx.value.length > 0 || true) }
				]),
        type: _ctx.type,
        placeholder: _ctx.placeholder,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        autocomplete: "off"
      }, null, 10, _hoisted_3), [
        [_vModelDynamic, _ctx.value]
      ])
    ]),
    (_ctx.type === 'password')
      ? (_openBlock(), _createBlock(_component_base_image_svg, {
          key: 1,
          class: _normalizeClass([
				'hd-form-field-module__icon',
				'hd-form-field-module__icon--view'
			]),
          source: require('!html-loader!../assets/icons/view.svg')
        }, null, 8, ["source"]))
      : _createCommentVNode("", true)
  ]))
}