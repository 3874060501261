/**
 * Export icons
 */
export default [
	'faBars',
	'faMagnifyingGlass',
	'faBell',
	'faChevronRight',	
	'faEllipsis',
	'faPlus'
];