/**
 * Composition method for overlay module
 * 
 * Author: Sahil David
 */
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export function overlayComposition () {
	const store = useStore();
	const route = useRoute();

	// Computed store value
	const overlay = computed((): string => store.getters.get_overlay);

	watch(() => route.path, () => {
		processOverlay();
	})

	// Close overlay method
	function processOverlay (key = ''): void {
		store.dispatch('axn_updateOverlay', key);
	}

	return { overlay, processOverlay }
}