/**
 * Authentication State - vuex module
 */
import { MutationTree, ActionTree, GetterTree } from 'vuex';

import { ILoginState } from './@types';

/**
 * State
 */
const state: ILoginState = {
	loggedIn: false,
};

/**
 * Mutations
 */
const mutations: MutationTree<ILoginState> = {
	updateLoggedIn (stateObj: ILoginState, value: boolean) {
		stateObj.loggedIn = value;
	},
};

/**
 * Getters
 */
const getters: GetterTree<ILoginState, any> = {
	get_loggedIn (stateObj: ILoginState): boolean {
		return stateObj.loggedIn;
	},
};

/**
 * Actions
 */
const actions: ActionTree<ILoginState, any> = {
	axn_updateLoggedIn (context: any, value: boolean) {
		context.commit('updateLoggedIn', value);
	},
};

/**
 * Export
 */
export default {
	namespace: true,
	state,
	mutations,
	getters,
	actions,
};
  