
import { defineComponent } from 'vue';

// Compositions
import { menuDataComposition } from '../services';

export default defineComponent({	
	props: {
		menu: {
			type: String,
			required: true
		}
	},
	setup (props) {
		return { ...menuDataComposition(props) }
	}
})
