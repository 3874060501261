import store from '@/bootstrap/services/store';

import { redirectToApp, redirectToLogin } from './redirect.service';
import { getTokens } from '../auth.tokens';

/**
 * Verify landing page access
 */
export async function verifyLandingStatus(to: any, from: any, next: any) {
	const { name } = to;

	console.log(name);

	/**
	 * Skip verification if not needed
	 */
	if (to.meta.disableAuthenticationValidation) { next(); return; }
	
	/**
	 * Login Status
	 */
	if (store.getters.get_loggedIn) {
		await redirectToApp(name, next);
	} else {
		const tokens = getTokens();
		const { accessToken, refreshToken } = tokens;

		if (accessToken && refreshToken) {
			store.dispatch('axn_updateLoggedIn', true);
			await redirectToApp(name, next); return;
		}
		redirectToLogin(name, next);
	}
}