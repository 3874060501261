/**
 * Main router entry point
 * 
 * Author: Sahil David <sahil.david@huumun.com>
 */

import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { verifyLandingStatus } from '@/core/services/routing/validations.services';

/**
 * Pre-built views
 */
const NotFound = () => import('@/core/views/NotFound.vue');

/**
 * Route main object
 */
const routes: Array<RouteRecordRaw> = [
	/**
	 * For making an application route, add redirect property to the route path
	 * to the landing-page route
	 */
	{
		path: '/',
		name: 'landing-page',
		redirect: '/datota/login'
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'not-found',
		component: NotFound,
	}
];

const router = createRouter({
	history: createWebHashHistory(),
	routes
});

// Navigation guard - Redirect Logic
router.beforeEach((to: any, from: any, next: any) => {
	verifyLandingStatus(to, from, next);
});

export default router;