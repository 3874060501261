/**
 * Config file for app menu
 * 
 * Author: Sahil David
 */

import { IMenuItems } from '../@types';

const menu: IMenuItems = [
	[
		{
			id: 'my-account',
			type: 'router-link',
			param: '/datota/my-account'
		}
	],
	[
		{
			id: 'help',
			type: 'router-link',
			param: '/datota/pages/help'
		},
		{
			id: 'report-an-issue',
			type: 'router-link',
			param: '/datota/pages/report-an-issue'
		},
		{
			id: 'about-datota',
			type: 'router-link',
			param: '/datota/pages/about-datota'
		}
	]
];

export default menu;