/**
 * Composition method for menu functions
 * 
 * Author: Sahil David
 */
import { onMounted, ref, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';

import { IMenuItems } from '../@types';

export function menuDataComposition (props) {
	const route = useRoute();

	// Data
	const { menu } = toRefs(props);
	const datum = ref<IMenuItems[][]>([]);

	// Lifecycle
	watch(() => route.path, driveData);
	onMounted(driveData);

	// Method to derive menu data
	function driveData () {
		datum.value = [];
		try {
			datum.value = require(`../configs/menu.${menu.value}`).default;
		} catch (err) {
			console.warn(err);
		}
	}

	return { datum, driveData }
}