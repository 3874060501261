
import { defineComponent } from 'vue'

export default defineComponent({
	props: {
		source: {
			type: String,
			required: true,
		},
		colour: {
			type: String,
			default: 'colour',
		},
		isImage: {
			type: Boolean,
			default: false
		},
		modifier: {
			type: String,
			default: 'default',
		}
	},
})
