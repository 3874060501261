import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e797ae9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "hd-display-restrictions-module"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_image_svg = _resolveComponent("base-image-svg")!

  return (_ctx.restricted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_base_image_svg, {
          class: "hd-display-restrictions-module__logo",
          source: "favicon.png",
          "is-image": true
        }),
        _createElementVNode("div", {
          class: "hd-display-restrictions-module__text",
          innerHTML: _ctx.$t('core.modules.displayRestrictions.message')
        }, null, 8, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}