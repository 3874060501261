/**
 * Core services file for utilities
 * =================================
 * - Strings
 * - Numbers
 * - Objects
 * 
 * Author: Sahil David <sahil.david@huumun.com>
 */

/**
 * Strings
 * =================================
 */

/**
 * Function to return unique Id.
 * @param value - `string` value to convert.
 * @returns 'string'. Id format to return.
 */
export function toKebabCaseId (value: string): string {
    if (!value) { return ''; }

	return value.trim().replace(/ /g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase();
}

/**
 * Numbers
 * =================================
 */

/**
 * Evaluates if given values is a valid number. 
 * @param value - Value to evaluate.
 * @returns `boolean`. Whether if the value is a number or not.
 */
export function isNumber (value: any): boolean {
	return typeof value === 'number';
}

/**
 * Objects
 * =================================
 */

/**
 * Bubble sort
 * @param datum - array to sort
 * @param map - array of map to sort
 * @returns - returns sorted array
 */
export function sortMappedArray (datum: any[], map: any[]): any[] {
	datum.sort((a, b) => map.indexOf(a) - map.indexOf(b));

	return datum;
}