/**
 * Module router file
 */
import { RouteRecordRaw } from 'vue-router';

const Module = () => import('./Module.vue');
const Dashboard = () => import('./views/Dashboard.vue');

/**
 * Route main object
 */
const moduleRoute: RouteRecordRaw = {
	path: 'dashboard',
	component: Module,
	children: [
		{
			path: '',
			component: Dashboard
		}
	]
}

export default moduleRoute;
