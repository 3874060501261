/**
 * Example appState - vuex module
 */
import { MutationTree, ActionTree, GetterTree } from 'vuex';

import { IApplicationState } from './@types';

/**
 * State
 */
const state: IApplicationState = {
	appState: '',
};

/**
 * Mutations
 */
const mutations: MutationTree<IApplicationState> = {
	updateAppState (stateObj: IApplicationState, appState: string) {
		stateObj.appState = appState;
	},
};

/**
 * Getters
 */
const getters: GetterTree<IApplicationState, any> = {
	get_appState (stateObj: IApplicationState): string {
		return stateObj.appState;
	},
};

/**
 * Actions
 */
const actions: ActionTree<IApplicationState, any> = {
	axn_updateAppState (context: any, appState: string) {
		context.commit('updateAppState', appState);
	},
};

/**
 * Export
 */
export default {
	namespace: true,
	state,
	mutations,
	getters,
	actions,
};
