/**
 * Main internationalisation plugin entry point (i18n).
 * 
 * Author: Margarida López <margarida.lopez@huumun.com>
 */

// Imports.
import { createI18n } from 'vue-i18n/index';
import { enGl } from '@/config/locales/en-gl';

// Main entry point.
const i18n = createI18n({
    locale: 'enGl',
    fallbackLocale: 'enGl',
    messages: {
        enGl,
    },
});

// Exports.
export default i18n;
