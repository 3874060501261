/**
 * Config file for accounts menu
 * 
 * Author: Sahil David
 */

import { IMenuItems } from '../@types';

const menu: IMenuItems = [
	[
		{
			id: 'log-out',
			type: 'router-link',
			param: '/datota/logout'
		}
	],
];
 
 export default menu;