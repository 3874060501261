/**
 * Module export file
 * 
 * Author: Sahil David <sahil.david@huumun.com>
 */
import camelCase from 'lodash/camelCase';

const storeModules: any[] = [];

/**
 * Export modules
 */
try {
	const requireModule = require.context('.', false, /\.ts$/);
	requireModule.keys().forEach((filename: string) => {
		if (filename === './index.ts') { return; }
		const module = requireModule(filename);

		const moduleName = camelCase(
			filename.replace(/(\.\/|\.ts)/g, '')
		);

		storeModules.push({
			name: moduleName,
			store: module.default || module
		});
	});
} catch (err) {
	console.warn(err);
}

/**
 * Export modules
 */
 try {
	const requireModule = require.context('../modules', true, /index$/);
	requireModule.keys().forEach((filename: string) => {
		const fragments = filename.split('/');
		if (fragments.length !== 3) { return; }

		const module = requireModule(filename);
		const moduleObj = module.default || module;


		if (!moduleObj.store) { return; }
		Object.keys(moduleObj.store).forEach((moduleName) => {
			storeModules.push({
				name: moduleName,
				store: moduleObj.store[moduleName]
			});
		});
	});
} catch (err) {
	console.warn(err);
}

/**
 * Exporting wrapper function to be added to main router object
 */
export default (): any[] => {
	return storeModules;
}
 