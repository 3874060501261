/**
 * Config file for title menu
 * 
 * Author: Sahil David
 */

import { IMenuItems } from '../@types';

const menu: IMenuItems = [
	[
		{
			id: 'members',
			type: 'router-link',
			param: '/datota/org/:organisationId/members'
		},
		{
			id: 'settings',
			type: 'router-link',
			param: '/datota/org/:organisationId/settings'
		},
	]
];

export default menu;