/**
 * Config file for create menu
 * 
 * Author: Sahil David
 */

import { IMenuItems } from '../@types';

const menu: IMenuItems = [
	[
		{
			id: 'project',
			type: 'router-link',
			param: '/datota/create/'
		},
		{
			id: 'chart',
			type: 'router-link',
			param: '/datota/create/'
		},
		{
			id: 'presentation',
			type: 'router-link',
			param: '/datota/create/'
		}
	],
];
 
 export default menu;