import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bee3aed4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hd-app-bar" }
const _hoisted_2 = { class: "hd-app-bar__icon" }
const _hoisted_3 = { class: "hd-app-bar__title" }
const _hoisted_4 = { class: "hd-app-bar__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_image_svg = _resolveComponent("base-image-svg")!
  const _component_menu_module = _resolveComponent("menu-module")!
  const _component_app_bar_menu = _resolveComponent("app-bar-menu")!
  const _component_ui_user_tag = _resolveComponent("ui-user-tag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_image_svg, {
        source: require('!html-loader!../assets/logos/app-logo.svg'),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.processMenu('app')))
      }, null, 8, ["source"]),
      _createVNode(_component_base_image_svg, {
        class: "hd-app-bar__chevron",
        source: require('!html-loader!../assets/icons/chevron-down.svg'),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.processMenu('app')))
      }, null, 8, ["source"]),
      _createVNode(_Transition, null, {
        default: _withCtx(() => [
          (_ctx.menu === 'app')
            ? (_openBlock(), _createBlock(_component_app_bar_menu, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_menu_module, { menu: "app" })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "hd-app-bar__title-copy",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.processMenu('title')))
      }, " huumun "),
      _createVNode(_component_base_image_svg, {
        class: "hd-app-bar__chevron",
        source: require('!html-loader!../assets/icons/chevron-down.svg'),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.processMenu('title')))
      }, null, 8, ["source"]),
      _createVNode(_Transition, null, {
        default: _withCtx(() => [
          (_ctx.menu === 'title')
            ? (_openBlock(), _createBlock(_component_app_bar_menu, {
                key: 0,
                modifier: "right"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_menu_module, { menu: "title" })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.processMenu('account')))
      }, [
        _createVNode(_component_ui_user_tag),
        _createVNode(_component_base_image_svg, {
          class: "hd-app-bar__chevron",
          source: require('!html-loader!../assets/icons/chevron-down.svg')
        }, null, 8, ["source"]),
        _createVNode(_Transition, null, {
          default: _withCtx(() => [
            (_ctx.menu === 'account')
              ? (_openBlock(), _createBlock(_component_app_bar_menu, {
                  key: 0,
                  modifier: "right"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_menu_module, { menu: "account" })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}