/**
 * English locale messages.
 * 
 * Author: Margarida López <margarida.lopez@huumun.com>
 */

// Messages.
const messages = () => ({
    login: {
        strapline: 'power up your scientific communications',
        title: 'Login',
        form: {
            email: 'Email',
			username: 'Email or Username',
            password: 'Password',
            remember: 'Remember me',
            reset: 'Forgot your password?',
            submit: 'SIGN IN'
        },
        unregistered: {
            title: 'Not a member?',
            submit: 'Register Now'
        }
    },
    appBar: {
        title: 'Welcome to datota'
    },
	menu: {
		items: {
			'about-datota': 'About Datota',
			'log-out': 'Log out',
			'my-account': 'My Account',
			'report-an-issue': 'Report an issue',
			chart: 'Chart',
			help: 'Help',
			members: 'Members',
			presentation: 'Presentation',
			project: 'Project',
			settings: 'Settings',
		}
	},
    core: {
        modules: {
            displayRestrictions: {
                message: 'Please use a bigger device to view this site.'
            }
        }
    },
});

// Exports.
export const enGl = messages();
export type LocaleMessages = ReturnType<typeof messages>;
