/**
 * File to add applications/projects modules here
 * 
 * Author: Sahil David
 */

import { IModule, Dictionary } from '@/@types';

/**
 * Applications import 
 */
import DatotaApp from '@/applications/datota';

/**
 * Object registering application modules
 */
export const applicationsModules: Dictionary<IModule> = {
	datota: DatotaApp,
	/**
	 * Add application modules
	 */
};