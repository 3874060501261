/**
 * Display Restrictions module main config file.
 *
 * Author: Margarida López <margarida.lopez@huumun.com>
 */

import { appConfig } from '@/config/app.config';
import { isNumber } from '@/core/services/core.utils';

// Variables
const { width, height } = appConfig.core?.modules?.displayRestrictions || {};

// App configuration object.
const config = () => ({
    width: isNumber(width) ? width : 1260,
    height: isNumber(height) ? height : 720,
});

// Exports
export const displayRestrictionsConfig = config();
export type DisplayRestrictionsConfig = ReturnType<typeof config>;
