/**
 * Main bootstrap execution file
 * 
 * Author: Sahil David <sahil.david@huumun.com>
 */

import { registerModules } from './register-modules';
import { coreModules } from '../core';
import { applicationsModules } from './applications-modules';

/**
 * Registering applications
 */
export default (): void => {
	registerModules(coreModules);
	registerModules(applicationsModules);
}