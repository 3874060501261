
import { defineComponent } from 'vue';
import { formFieldComposition } from '../services/form-field.composition';

export default defineComponent({
	props: {
		modifier: {
			type: String,
			default: 'default',
		},
		type: {
			type: String,
			default: 'text',
		},
		icon: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		callback: {
			type: Function,
			default: () => {
				/***
				 * Placeholder callback function
				 */
			},
		}
	},
	setup (props) {
		return { ...formFieldComposition(props) }
	},
})
