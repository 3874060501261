import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "hd-app-menu__list" }
const _hoisted_2 = {
  key: 1,
  class: "hd-app-menu__item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.datum, (block, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "hd-app-menu__section"
      }, [
        _createElementVNode("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(block, (item) => {
            return (_openBlock(), _createElementBlock("li", {
              key: item.id,
              class: "hd-app-menu__list-item"
            }, [
              (item.type === 'router-link')
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: item.param,
                    class: "hd-app-menu__item"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(`menu.items.${item.id}`)), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t(`menu.items.${item.id}`)), 1))
            ]))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}