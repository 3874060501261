/**
 * Main app entry point - vue app
 * 
 * Author: Sahil David <sahil.david@huumun.com>
 */

/**
 * Initialise Vue 3.0 app
 * Registering with root component App.vue
 */
import { createApp } from 'vue';
import App from '@/App.vue';
const app = createApp(App);

/**
 * Import global styles
 */
import '@/ui/styles/core.scss';

/**
 * Bootstrap services inclusion
 */
import router from '@/bootstrap/services/router';
import store from '@/bootstrap/services/store';
import i18n from '@/bootstrap/services/i18n';
import bootstrap from '@/bootstrap';

// Registering services
bootstrap();
app.use(router);
app.use(store);
app.use(i18n);

/**
 * Registering components library
 */
import components from '@/core/components';
components(app);

/**
 * Registering plugins library
 */
import plugins from '@/core/plugins';
plugins(app);

/**
 * Mounting vue app on DOM element
 */
app.mount('#app');
