/**
 * Font awesome icons plugin - library entry point
 * 
 * Author: Sahil David <sahil.david@huumun.com>
 */
import { App } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

/**
 * Initialise Library
 */
function initialiseLibrary () {
	try {
		const iconsToAdd: any[] = [];

		// Dynamic icon load
		const iconsConfig = require('@/config/icons.load').default;
		iconsConfig.forEach((_icon: string) => {
			const icon = require('@fortawesome/free-solid-svg-icons')[_icon];
			if (icon) {
				iconsToAdd.push(icon);
			}
		});
		
		library.add(...iconsToAdd);
	} catch (err) {
		console.warn(err);
	}
}

export default (app: App<Element>): void => {
	initialiseLibrary();
	app.component('hd-icon', FontAwesomeIcon);
}