/**
 * Register all components to the Vue instance
 * 
 * Author: Sahil David <sahil.david@huumun.com>
 */
import { App } from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

/**
 * Register base components
 * @param app - app to register components to
 */
function coreComponents (app: App<Element>) {
	// Require components in base context
	const requireComponent = require.context('.', false, /\.vue$/);

	requireComponent.keys().forEach((filename: string) => {
		// Get component config
		const componentConfig = requireComponent(filename);

		// Get PascalCase name of component
		const componentName = upperFirst(
			camelCase(filename.replace(/^\.\//, '').replace(/\.\w+$/, ''))
		);

		// Register component globally
		app.component(componentName, componentConfig.default || componentConfig);
	});
}

/**
 * Register module components
 * @param app - app to register components to
 */
function moduleComponents (app: App<Element>) {
	// Require components in base context
	const requireComponent = require.context('../modules', true, /\.vue$/);

	requireComponent.keys().forEach((filename: string) => {
		const fragments: string[] = filename.split('/');
		const vueFilename = fragments.find((_: string) => _.includes('.vue'));

		if (!vueFilename) { return; }
		
		// Get component config
		const componentConfig = requireComponent(filename);

		// Get PascalCase name of component
		const componentName = upperFirst(
			camelCase(vueFilename.replace(/^\.\//, '').replace(/\.\w+$/, ''))
		);

		// Register component globally
		app.component(componentName, componentConfig.default || componentConfig);
	});
}

/**
 * Register plugins components
 * @param app - app to register components to
 */
function pluginComponents (app: App<Element>) {
	// Require components in base context
	const requireComponent = require.context('../../plugins', true, /\.vue$/);

	requireComponent.keys().forEach((filename: string) => {
		const fragments: string[] = filename.split('/');
		const vueFilename = fragments.find((_: string) => _.includes('.vue'));
		
		if (!vueFilename) { return; }
		
		// Get component config
		const componentConfig = requireComponent(filename);

		// Get PascalCase name of component
		const componentName = upperFirst(
			camelCase(vueFilename.replace(/^\.\//, '').replace(/\.\w+$/, ''))
		);

		// Register component globally
		app.component(componentName, componentConfig.default || componentConfig);
	});
}

/**
 * Components registration
 */
export default (app: App<Element>): void => {
	coreComponents(app);
	moduleComponents(app);
	pluginComponents(app);
}