/**
 * Export application module export Module object
 */
import router from './router';
import store from './store';

export default {
	router,
	store
};
 
  