
import { defineComponent } from 'vue'

export default defineComponent({
	props: {
		modifier: {
			type: String,
			default: 'default'
		}
	},
})
