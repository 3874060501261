import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1567fc7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hd-overlay-module__content" }
const _hoisted_2 = { class: "hd-overlay-module__body" }
const _hoisted_3 = { class: "hd-overlay-module__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_image_svg = _resolveComponent("base-image-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
			'hd-overlay-module',
			`hd-overlay-module__${_ctx.modifier}`,
		])
  }, [
    _createElementVNode("div", {
      class: "hd-overlay-module__close-wrapper",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.processOverlay()))
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_base_image_svg, {
        class: "hd-overlay-module__close-button crux-click",
        source: require('!html-loader!../assets/images/close.svg'),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.processOverlay())),
        "data-crux": "overlay-close"
      }, null, 8, ["source"]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
      ])
    ])
  ], 2))
}